import React from "react";
import {
  Box,
  Container,
  Flex,
  Text,
  IconButton,
  Button,
  Stack,
  Collapse,
  Icon,
  Link,
  useColorModeValue,
  useBreakpointValue,
  useDisclosure,
} from "@chakra-ui/react";
import { HamburgerIcon, CloseIcon, ChevronDownIcon } from "@chakra-ui/icons";
import Scrollspy from "react-scrollspy";

import words from "../words";

export default function WithSubnavigation({ active, data }) {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Box
      bg={active ? "white" : "transparent"}
      position="fixed"
      w="100%"
      transition="background-color 0.25s ease"
      zIndex="99"
    >
      <Flex
        minH="65px"
        py={{ base: 2 }}
        px={{ base: 4 }}
        borderBottom={1}
        align="center"
      >
        <Container maxW="container.lg" px={{ base: 0, lg: 4 }}>
          <Flex>
            <Flex flex={{ base: 1 }} align="center">
              <Text
                textAlign={useBreakpointValue({ base: "center", lg: "left" })}
                fontFamily="heading"
                color="brandPrimary"
              >
                {data.pageTitle}
              </Text>
            </Flex>

            <Scrollspy
              items={["about", "service", "client", "talent", "newsletter"]}
            >
              <Stack
                flex={{ base: 1 }}
                align="center"
                justify="flex-end"
                direction="row"
                display={{ base: "none", lg: "flex" }}
                spacing={6}
              >
                <DesktopNav data={{ navbarLinks: data.navbarLinks }} />
                <Button
                  as={Link}
                  display={{ base: "none", lg: "inline-flex" }}
                  color="white"
                  fontSize="sm"
                  fontWeight="400"
                  bg="brandPrimary"
                  href={data.navbarButton.link}
                  _hover={{ bg: "brandPrimary" }}
                >
                  {data.navbarButton.text}
                </Button>
              </Stack>
            </Scrollspy>

            <Flex
              flex={{ base: 1, lg: "auto" }}
              ml={{ base: -2 }}
              display={{ base: "flex", lg: "none" }}
              justify="flex-end"
            >
              <IconButton
                minW="auto"
                onClick={onToggle}
                icon={
                  isOpen ? (
                    <CloseIcon w={3} h={3} />
                  ) : (
                    <HamburgerIcon w={5} h={5} />
                  )
                }
                variant="ghost"
                aria-label="Toggle Navigation"
              />
            </Flex>
          </Flex>
        </Container>
      </Flex>

      <Collapse in={isOpen} animateOpacity>
        <MobileNav
          data={{
            navbarLinks: data.navbarLinks,
            navbarButton: data.navbarButton,
          }}
        />
      </Collapse>
    </Box>
  );
}

const DesktopNav = ({ data }) => {
  const grayToGrayColor = useColorModeValue("gray.600", "gray.200");
  const grayToWhiteColor = useColorModeValue("gray.800", "white");

  return (
    <Stack direction="row" spacing={4}>
      {data.navbarLinks.map((navItem) => (
        <Box key={navItem.label}>
          <Link
            p={2}
            href={navItem.link ?? "#"}
            fontSize="sm"
            fontWeight={500}
            color={grayToGrayColor}
            _focus={{
              outline: "none",
            }}
            _hover={{
              textDecoration: "none",
              color: grayToWhiteColor,
            }}
          >
            {navItem.text}
          </Link>
        </Box>
      ))}
    </Stack>
  );
};

const MobileNav = ({ data }) => {
  return (
    <Scrollspy items={["about", "service", "client", "talent", "newsletter"]}>
      <Stack
        bg={useColorModeValue("white", "gray.800")}
        p={8}
        display={{ lg: "none" }}
      >
        {data.navbarLinks.map((navItem) => (
          <MobileNavItem key={navItem.label} {...navItem} />
        ))}
        <Button
          as={Link}
          color="white"
          fontSize="sm"
          fontWeight="400"
          bg="brandPrimary"
          href={data.navbarButton.link}
          _hover={{
            bg: "brandPrimary",
            textDecoration: "none",
          }}
        >
          {data.navbarButton.text}
        </Button>
      </Stack>
    </Scrollspy>
  );
};

const MobileNavItem = ({ text, children, link }) => {
  const { isOpen, onToggle } = useDisclosure();

  return (
    <Stack spacing={4} onClick={children && onToggle}>
      <Flex
        py={2}
        as={Link}
        href={link ?? "#"}
        justify="space-between"
        align="center"
        _hover={{
          textDecoration: "none",
        }}
      >
        <Text
          fontWeight={600}
          color={useColorModeValue("gray.600", "gray.200")}
        >
          {text}
        </Text>
        {children && (
          <Icon
            as={ChevronDownIcon}
            transition={"all .25s ease-in-out"}
            transform={isOpen ? "rotate(180deg)" : ""}
            w={6}
            h={6}
          />
        )}
      </Flex>

      <Collapse in={isOpen} animateOpacity style={{ marginTop: "0!important" }}>
        <Stack
          mt={2}
          pl={4}
          borderLeft={1}
          borderStyle="solid"
          borderColor={useColorModeValue("gray.200", "gray.700")}
          align="start"
        >
          {children &&
            children.map((child) => (
              <Link key={child.label} py={2} href={child.href}>
                {child.label}
              </Link>
            ))}
        </Stack>
      </Collapse>
    </Stack>
  );
};

const NAV_ITEMS = [
  {
    label: words.navbar.about,
    href: "#about",
  },
  {
    label: words.navbar.service,
    href: "#service",
  },
  {
    label: words.navbar.client,
    href: "#client",
  },
  {
    label: words.navbar.talent,
    href: "#talent",
  },
];
