import * as React from "react";
import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";

import imgClient from "../assets/images/client.jpg";

const Client = ({ data }) => (
  <div id="client">
    <Box py={{ base: 0, lg: 16 }} pb={16}>
      <Box p={{ base: 0, lg: 4 }} color="white">
        <Container maxW="container.lg" px={{ base: 0, lg: 4 }}>
          <Flex
            minH={640}
            p={{ base: 4, lg: 16 }}
            borderRadius={{ base: "none", lg: "3xl" }}
            bgGradient={`linear(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(6, 190, 225, 1)), url(${imgClient})`}
            bgPosition={{ base: "25% center", lg: "center center" }}
            bgRepeat="no-repeat"
            bgSize="cover"
            direction={{ base: "column", lg: "row" }}
          >
            <Flex
              align="flex-end"
              w={{ base: "100%", lg: "65%" }}
              pt={{ base: 360, lg: 0 }}
            >
              <Box>
                <Text py={4}>{data.clientTitle}</Text>
                <Heading
                  as="h1"
                  whiteSpace={{ base: "normal", lg: "pre-line" }}
                  fontSize={{ base: "24px", lg: "3xl" }}
                  maxW={{ lg: "550px" }}
                >
                  {data.clientTitle2}
                </Heading>
              </Box>
            </Flex>
            <Flex
              direction="column"
              justify="space-between"
              w={{ base: "100%", lg: "35%" }}
              pt={{ base: 4, lg: 0 }}
            >
              {data.clientFeatures.map((feat) => {
                return (
                  <Box
                    px={12}
                    py={{ base: 8, lg: 12 }}
                    bg={"#06BEE1"}
                    borderRadius="xl"
                    fontSize="lg"
                    fontWeight="bold"
                    position="relative"
                    mb={{ base: 4, lg: 0 }}
                    ml={{ base: 4, lg: 0 }}
                  >
                    {feat.title}
                    <Box
                      bg="brandSecondary"
                      borderRadius="full"
                      fontSize={{ base: "xl", md: "2xl" }}
                      p={4}
                      position="absolute"
                      top={{ base: "20%", lg: "30%" }}
                      left="-24px"
                    >
                      <img
                        alt="icon-work"
                        src={feat.image.file.url}
                        width="20"
                        height="20"
                      />
                    </Box>
                  </Box>
                );
              })}
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  </div>
);

export default Client;
