import * as React from "react";
import { Box } from "@chakra-ui/react";

const BoxRounded = ({ children, isLight = false }) => (
  <Box
    align={isLight ? "left" : "center"}
    p={{ base: 4, lg: 8 }}
    bg={isLight ? "#FFFFFF" : "#CDF2F9"}
    borderRadius="lg"
  >
    {children}
  </Box>
);

export default BoxRounded;
