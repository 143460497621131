import * as React from "react";
import { Box, Container, Flex, Link, SimpleGrid, Text } from "@chakra-ui/react";
import { FaFacebookSquare } from "@react-icons/all-files/fa/FaFacebookSquare";
import { FaInstagramSquare } from "@react-icons/all-files/fa/FaInstagramSquare";
import { FaLinkedin } from "@react-icons/all-files/fa/FaLinkedin";
import words from "../words";

const Footer = ({ data }) => (
  <Box px={{ base: 4 }} py={{ base: 12 }} bg="white">
    <Container maxW="container.lg">
      <SimpleGrid columns={{ base: 1, lg: 2 }} spacing={{ base: 4, lg: 6 }}>
        <Box order={{ base: 2, lg: 1 }}>
          <Link
            href={data.footerLink.link}
            color="brandPrimary"
            fontSize="xl"
            fontWeight="bold"
            display={{ base: "block", lg: "inline" }}
            textAlign={{ base: "center", lg: "left" }}
            pb={{ base: 2, lg: 0 }}
          >
            {data.footerLink.text}
          </Link>
          <Text
            as="div"
            color="brandFooterText"
            display={{ base: "block", lg: "inline" }}
            fontSize={{ base: "xs", lg: "sm" }}
            pl={{ base: 0, lg: 4 }}
            textAlign={{ base: "center", lg: "left" }}
          >
            {data.footerCopyright}
          </Text>
        </Box>
        <Box order={{ base: 1, lg: 2 }}>
          <Flex align="center" justify={{ base: "center", lg: "flex-end" }}>
            <Link
              href={data.footerLinkedin}
              color="brandPrimary"
              fontSize="3xl"
              fontWeight="bold"
              pl={{ base: 2, lg: 4 }}
              pr={{ base: 2, lg: 0 }}
            >
              <FaLinkedin />
            </Link>
            <Link
              href={data.footerFacebook}
              color="brandPrimary"
              fontSize="3xl"
              fontWeight="bold"
              pl={{ base: 2, lg: 4 }}
              pr={{ base: 2, lg: 0 }}
            >
              <FaFacebookSquare />
            </Link>
            <Link
              href={data.footerInstagram}
              color="brandPrimary"
              fontSize="3xl"
              fontWeight="bold"
              pl={{ base: 2, lg: 4 }}
              pr={{ base: 2, lg: 0 }}
            >
              <FaInstagramSquare />
            </Link>
          </Flex>
        </Box>
      </SimpleGrid>
    </Container>
  </Box>
);

export default Footer;
