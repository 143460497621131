import * as React from "react";
import { Box, Container, Heading, SimpleGrid, Text } from "@chakra-ui/react";
import styled from "@emotion/styled";

import svgBrowser from "../assets/images/browser.svg";
import svgUserInterface from "../assets/images/user-interface.svg";
import svgAutomation from "../assets/images/automation.svg";
import svgManyMore from "../assets/images/many-more.svg";
import curved from "../assets/images/curved.jpg";
import curvedMobile from "../assets/images/curved-mobile.jpg";
import BoxRounded from "../components/BoxRounded";
import words from "../words";

const ServiceBackground = styled.div`
  background-image: url("${(props) => props.src}");
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 48em) {
    background-image: url("${(props) => props.srcMobile}");
  }
`;

const Service = ({ data }) => (
  <div id="service">
    <ServiceBackground src={curved} srcMobile={curvedMobile}>
      <Box py={{ base: 16, lg: 40 }}>
        <Box p={{ base: 4 }}>
          <Container maxW="container.lg" px={{ base: 0, lg: 4 }}>
            <Text align="center" color="brandPrimary">
              {data.ourServiceTitle}
            </Text>
            <Heading
              align="center"
              as="h1"
              py={4}
              fontSize={{ base: "xl", lg: "3xl" }}
            >
              {data.ourServiceTitle2}
            </Heading>
            <SimpleGrid
              columns={{ base: 2, lg: 4 }}
              spacing={{ base: 4, lg: 6 }}
            >
              {data.services.map((service) => {
                return (
                  <BoxRounded>
                    <img
                      src={service.image.file.url}
                      alt={words.service.website}
                      loading="lazy"
                    />
                    <Heading
                      as="h2"
                      pt={2}
                      fontSize={{ base: "xs", lg: "md" }}
                      whiteSpace="nowrap"
                    >
                      {service.title}
                    </Heading>
                  </BoxRounded>
                );
              })}
            </SimpleGrid>
          </Container>
        </Box>
      </Box>
    </ServiceBackground>
  </div>
);

export default Service;
