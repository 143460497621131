import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Flex,
  Heading,
  Input,
  Text,
} from "@chakra-ui/react";
import styled from "@emotion/styled";
import addToMailchimp from "gatsby-plugin-mailchimp";

import imgNewsletter from "../assets/images/newsletter.jpg";
import imgNewsletterMobile from "../assets/images/newsletter-mobile.jpg";
import words from "../words";

const NewsletterBackground = styled.div`
  background-image: url("${(props) => props.src}");
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 48em) {
    background-image: url("${(props) => props.srcMobile}");
  }
`;

const Newsletter = ({ data }) => {
  const [email, setEmail] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  React.useEffect(() => {
    setTimeout(() => {
      setIsSuccess(false);
      setIsError(false);
    }, 5000);
  }, [isSuccess, isError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email);
    if (result.result === "success") {
      setMsg(result.msg);
      setIsSuccess(true);
      setIsError(false);
      setEmail("");
    } else {
      setMsg(`${email} is already subscribed`);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return (
    <div id="newsletter">
      <Box bg="white" pt={{ base: 16, lg: 12 }}>
        <NewsletterBackground
          src={imgNewsletter}
          srcMobile={imgNewsletterMobile}
        >
          <Flex
            align="center"
            justify={{ base: "center", lg: "start" }}
            w="100%"
            h={640}
            px={4}
            py={20}
          >
            <Box w="100%" textAlign="center">
              <Heading
                as="h1"
                fontSize={{ base: "24px", md: "3xl" }}
                color="white"
              >
                {data.newsletterTitle}
              </Heading>
              <Box
                color="white"
                whiteSpace={{ base: "normal", lg: "pre-line" }}
                py={8}
              >
                <Text maxW={{ lg: "700px" }} mx={{ lg: "auto" }}>
                  {data.newsletterDescription}
                </Text>
              </Box>
              <form
                onSubmit={handleSubmit}
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Input
                  placeholder={data.newsletterInputPlaceholder}
                  bg="white"
                  maxW="420px"
                  w={{ base: "calc(70% - 16px)", lg: "30%" }}
                  onChange={(e) => setEmail(e.target.value)}
                  value={email}
                />
                <Button
                  background="brandSecondary"
                  color="white"
                  fontWeight="normal"
                  size="md"
                  type="submit"
                  w={{ base: "30%", lg: "auto" }}
                  ml={4}
                  px={8}
                  _hover={{ bg: "brandSecondary" }}
                >
                  {data.newsletterButtonText}
                </Button>
              </form>
              {isSuccess && (
                <Alert status="success" mt={4}>
                  {msg}
                </Alert>
              )}
              {isError && (
                <Alert status="error" mt={4}>
                  {msg}
                </Alert>
              )}
            </Box>
          </Flex>
        </NewsletterBackground>
      </Box>
    </div>
  );
};

export default Newsletter;
