import * as React from "react";
import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";

import imgTalent from "../assets/images/talent.jpg";
import imgWork from "../assets/images/work.svg";
import imgSend from "../assets/images/send.svg";
import imgMoney from "../assets/images/money.svg";
import words from "../words";

const Talent = ({ data }) => (
  <div id="talent">
    <Box pb={{ base: 0, lg: 16 }}>
      <Box p={{ base: 0, lg: 4 }} color="white">
        <Container maxW="container.lg" px={{ base: 0, lg: 4 }}>
          <Flex
            minH={640}
            p={{ base: 4, lg: 16 }}
            borderRadius={{ base: "none", lg: "3xl" }}
            bgGradient={`linear(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0), rgba(6, 190, 225, 1)), url(${imgTalent})`}
            bgPosition="center center"
            bgRepeat="no-repeat"
            bgSize="cover"
            direction={{ base: "column", lg: "row" }}
          >
            <Flex
              direction="column"
              justify="space-between"
              w={{ base: "100%", lg: "35%" }}
              order={{ base: 2, lg: 1 }}
              pt={{ base: 4, lg: 0 }}
            >
              {data.talentFeatures.map((feat) => {
                return (
                  <Box
                    px={12}
                    py={{ base: 8, lg: 12 }}
                    bg={"#06BEE1"}
                    borderRadius="xl"
                    fontSize="lg"
                    fontWeight="bold"
                    position="relative"
                    mb={{ base: 4, lg: 0 }}
                    ml={{ base: 4, lg: 0 }}
                  >
                    {feat.title}
                    <Box
                      bg="brandSecondary"
                      borderRadius="full"
                      fontSize={{ base: "xl", md: "2xl" }}
                      p={4}
                      position="absolute"
                      top={"30%"}
                      left={"-24px"}
                    >
                      <img
                        alt="icon-work"
                        src={feat.image.file.url}
                        width="20"
                        height="20"
                      />
                    </Box>
                  </Box>
                );
              })}
              {/* <Box
                px={12}
                py={{ base: 8, lg: 12 }}
                bg={"#06BEE1"}
                borderRadius="xl"
                fontSize="lg"
                fontWeight="bold"
                position="relative"
                mb={{ base: 4, lg: 0 }}
                ml={{ base: 4, lg: 0 }}
              >
                {words.talent.itemOne}
                <Box
                  bg="brandSecondary"
                  borderRadius="full"
                  fontSize={{ base: "xl", md: "2xl" }}
                  p={4}
                  position="absolute"
                  top={"30%"}
                  left={"-24px"}
                >
                  <img alt="icon-work" src={imgWork} width="20" height="20" />
                </Box>
              </Box>
              <Box
                px={12}
                py={{ base: 8, lg: 12 }}
                bg={"#06BEE1"}
                borderRadius="xl"
                fontSize="lg"
                fontWeight="bold"
                position="relative"
                mb={{ base: 4, lg: 0 }}
                ml={{ base: 4, lg: 0 }}
              >
                {words.talent.itemTwo}
                <Box
                  bg="brandSecondary"
                  borderRadius="full"
                  fontSize={{ base: "xl", md: "2xl" }}
                  p={4}
                  position="absolute"
                  top={"30%"}
                  left={"-24px"}
                >
                  <img alt="icon-send" src={imgSend} width="20" height="20" />
                </Box>
              </Box>
              <Box
                px={12}
                py={{ base: 8, lg: 12 }}
                bg={"#06BEE1"}
                borderRadius="xl"
                fontSize="lg"
                fontWeight="bold"
                position="relative"
                mb={{ base: 4, lg: 0 }}
                ml={{ base: 4, lg: 0 }}
              >
                {words.talent.itemThree}
                <Box
                  bg="brandSecondary"
                  borderRadius="full"
                  fontSize={{ base: "xl", md: "2xl" }}
                  p={4}
                  position="absolute"
                  top={"30%"}
                  left={"-24px"}
                >
                  <img alt="icon-money" src={imgMoney} width="20" height="20" />
                </Box>
              </Box> */}
            </Flex>
            <Flex
              justify="flex-end"
              align="flex-end"
              w={{ base: "100%", lg: "65%" }}
              pt={{ base: 360, lg: 0 }}
              order={{ base: 1, lg: 2 }}
            >
              <Box>
                <Text textAlign={{ base: "left", lg: "right" }} py={4}>
                  {data.talentTitle}
                </Text>
                <Heading
                  textAlign={{ base: "left", lg: "right" }}
                  as="h1"
                  fontSize={{ base: "24px", lg: "3xl" }}
                  whiteSpace={{ base: "normal", lg: "pre-line" }}
                >
                  {data.talentTitle2}
                </Heading>
              </Box>
            </Flex>
          </Flex>
        </Container>
      </Box>
    </Box>
  </div>
);

export default Talent;
