import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  colors: {
    brandBodyText: "#76767C",
    brandFooterText: "#1A1B25",
    brandPrimary: "#01AEF2",
    brandSecondary: "#9400D3",
  },
  fonts: {
    heading: "Plus Jakarta Bold",
    body: "Plus Jakarta",
  },
  fontSizes: {
    "2xl": "2rem",
    "3xl": "2.5rem",
  },
});

export default theme;
