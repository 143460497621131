import * as React from "react";
import { StaticImage } from "gatsby-plugin-image";

const words = {
  navbar: {
    logo: "Lowlancer",
    about: "About Us",
    service: "Services",
    client: "For Client",
    talent: "For Makers",
    join: "Join Now",
  },
  hero: {
    title: "\nBuild apps..FAST🚀",
    desc: "Need an MVP or have a quick process to automate?. Are you a Maker with low code skills?",
    input: "Enter Your Email",
    join: "Join Now",
  },
  about: {
    subtitle: "About Us",
    title: "Find the perfect maker, for your market.",
    desc: "Lowlancer brings together Clients and Makers to achieve business value and build products using no or low code tool. Deliver fast, get paid quickly!",
  },
  service: {
    subtitle: "Our Services",
    title: "What You Need Is Here. Make at the speed innovation!",
    website: "Websites",
    mobile: "Mobile Apps",
    automation: "Automations",
    other: "And Many More",
  },
  tools: {
    subtitle: "Tools",
    title: "No-Code \ntools ready \nto help.",
    slides: [
      {
        id: 1,
        img: (
          <StaticImage
            src={"./assets/images/tools-1.png"}
            alt="Adalo"
            placeholder="blurred"
          />
        ),
        desc: "",
      },
      {
        id: 2,
        img: (
          <StaticImage
            src={"./assets/images/tools-2.png"}
            alt="Bubble"
            placeholder="blurred"
          />
        ),
        desc: "",
      },
      /* {
        id: 3,
        img: (
          <StaticImage
            src={"./assets/images/tools-3.png"}
            alt="Weglot"
            placeholder="blurred"
          />
        ),
        desc: "",
      } */,
      /* {
        id: 4,
        img: (
          <StaticImage
            src={"./assets/images/tools-4.png"}
            alt="Sharetribe"
            placeholder="blurred"
          />
        ),
        desc: "",
      } */,
    ],
  },
  client: {
    subtitle: "Client",
    title: "Hire the exact maker \nyour're looking for your app",
    itemOne: "Post the jobs you have.",
    itemTwo: "Do job negotiations and select talents.",
    itemThree: "Your work will be done by talent.",
  },
  talent: {
    subtitle: "Talent",
    title: "Open Your Opportunities \nand Earn Your Income",
    itemOne: "Find many job offers according to your skills.",
    itemTwo: "Apply and negotiate jobs.",
    itemThree: "Finish your work fast and earn your income.",
  },
  newsletter: {
    title: "Join Our Wait List Now!",
    desc: "lowlancer is currently under development🛠. Enter your email address and we'll let you know \nwhen we're ready.",
    input: "Enter Your Email",
    join: "Join Now🚀",
  },
  footer: {
    logo: "Lowlancer.com",
    copyright: "Copyright lowlancer.com 2022",
    linkLinkedin: "https://www.linkedin.com/company/lowlancer",
    //linkInstagram: "https://instagram.com",
    //linkFacebook: "https://facebook.com",
  },
};

export default words;
