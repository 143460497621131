import * as React from "react";
import { Box, Container, Flex, Heading, Text } from "@chakra-ui/react";
import { Splide, SplideSlide } from "@splidejs/react-splide";

import BoxRounded from "../components/BoxRounded";
import words from "../words";

const Tools = ({ data }) => {
  if (typeof window === "undefined") return <div />;

  return (
    <div>
      <Box py={20} position="relative">
        <div className="curved-shape-divider">
          <svg
            data-name="Shape Divider"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 1200 120"
            preserveAspectRatio="none"
          >
            <path
              d="M600,112.77C268.63,112.77,0,65.52,0,7.23V120H1200V7.23C1200,65.52,931.37,112.77,600,112.77Z"
              className="shape-fill"
            ></path>
          </svg>
        </div>
        <Box p={{ base: 4 }}>
          <Container maxW="container.lg" p={{ base: 0, lg: 4 }}>
            <Flex direction={{ base: "column", lg: "row" }}>
              <Box w={{ base: "100%", lg: "25%" }}>
                <Text color="brandSecondary">{data.noCodeTitle}</Text>
                <Heading
                  as="h1"
                  py={4}
                  whiteSpace={{ base: "normal", lg: "pre-line" }}
                  fontSize={{ base: "24px", lg: "3xl" }}
                >
                  {data.noCodeTitle2}
                </Heading>
              </Box>
              <Box w={{ base: "100%", lg: "75%" }}>
                <Splide
                  options={{
                    autoplay: true,
                    breakpoints: {
                      640: {
                        perPage: 1,
                      },
                    },
                    gap: "1.5rem",
                    pagination: false,
                    perPage: 3,
                    type: "loop",
                  }}
                >
                  {data.noCodeSlider.map((slide) => {
                    return (
                      <SplideSlide key={slide.id}>
                        <BoxRounded isLight>
                          <img
                            src={slide.image.file.url}
                            alt=""
                            style={{ maxHeight: 64 }}
                          />
                        </BoxRounded>
                      </SplideSlide>
                    );
                  })}
                </Splide>
              </Box>
            </Flex>
          </Container>
        </Box>
      </Box>
    </div>
  );
};

export default Tools;
