import * as React from "react";
import styled from "@emotion/styled";
import imgBg from "../assets/images/bg.png";

const Blurry = styled.div`
  background-image: linear-gradient(
      rgba(0, 0, 0, 0),
      rgba(0, 0, 0, 0),
      rgba(255, 255, 255, 1)
    ),
    url("${(props) => props.src}");
  background-repeat: no-repeat;
  background-size: ${(props) => (props.isContain ? "contain" : "cover")};
  @media (max-width: 48em) {
    background-image: ${(props) =>
      props.onlyLarge ? "none" : `url("${props.src}")`};
    background-color: #d8f5fb;
  }
`;

const BlurryBackground = ({
  children,
  isContain = false,
  onlyLarge = false,
}) => (
  <Blurry src={imgBg} isContain={isContain} onlyLarge={onlyLarge}>
    {children}
  </Blurry>
);

export default BlurryBackground;
