import * as React from "react";
import {
  Alert,
  Box,
  Button,
  Flex,
  Container,
  Heading,
  Input,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import addToMailchimp from "gatsby-plugin-mailchimp";

import words from "../words";

const Hero = ({ data }) => {
  const [email, setEmail] = React.useState("");
  const [isSuccess, setIsSuccess] = React.useState(false);
  const [isError, setIsError] = React.useState(false);
  const [msg, setMsg] = React.useState("");

  const image = getImage(data.heroImage);

  React.useEffect(() => {
    setTimeout(() => {
      setIsSuccess(false);
      setIsError(false);
    }, 5000);
  }, [isSuccess, isError]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    const result = await addToMailchimp(email);
    if (result.result === "success") {
      setMsg(result.msg);
      setIsSuccess(true);
      setIsError(false);
      setEmail("");
    } else {
      setMsg(`${email} is already subscribed`);
      setIsSuccess(false);
      setIsError(true);
    }
  };

  return (
    <div id="home">
      <Box pt={24} pb={4} px={4}>
        <Container maxW="container.lg" px={{ base: 0, lg: 4 }}>
          <SimpleGrid columns={{ base: 1, lg: 2 }}>
            <Flex align="center" justify={{ base: "center", lg: "start" }}>
              <Box>
                <Heading
                  as="h1"
                  fontSize={{ base: "2xl", md: "3xl" }}
                  textAlign={{ base: "center", lg: "left" }}
                  whiteSpace={{ base: "normal", lg: "pre-line" }}
                >
                  {data.heroTitle}
                </Heading>
                <Text
                  color={"brandBodyText"}
                  py={4}
                  textAlign={{ base: "center", lg: "left" }}
                >
                  {data.heroDescription.heroDescription}
                </Text>
                <form
                  onSubmit={handleSubmit}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <Input
                    placeholder={data.heroInputPlaceholder}
                    bg="white"
                    w={{ base: "60%", lg: "45%" }}
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                  <Button
                    bg="brandPrimary"
                    color="white"
                    w={{ base: "calc(40% - 16px)", lg: "25%" }}
                    type="submit"
                    ml={4}
                    _hover={{ bg: "brandPrimary" }}
                  >
                    {data.heroButtonText}
                  </Button>
                </form>
                {isSuccess && (
                  <Alert status="success" mt={4}>
                    {msg}
                  </Alert>
                )}
                {isError && (
                  <Alert status="error" mt={4}>
                    {msg}
                  </Alert>
                )}
              </Box>
            </Flex>
            <Box pt={{ base: 8, lg: 0 }}>
              <GatsbyImage image={image} />
            </Box>
          </SimpleGrid>
        </Container>
      </Box>
    </div>
  );
};

export default Hero;
