import * as React from "react";
import { Helmet } from "react-helmet";
import { ChakraProvider } from "@chakra-ui/react";
import "@splidejs/splide/dist/css/themes/splide-default.min.css";
import { graphql } from "gatsby";

import BlurryBackground from "../components/BlurryBackground";
import Navbar from "../components/Navbar";
import Hero from "../components/Hero";
import About from "../components/About";
import Service from "../components/Service";
import Tools from "../components/Tools";
import Client from "../components/Client";
import Talent from "../components/Talent";
import Newsletter from "../components/Newsletter";
import Footer from "../components/Footer";
import theme from "../styles/theme";
import "../assets/css/typography.css";

const IndexPage = ({ data }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const onScroll = (e) => {
    setIsVisible(e.target.documentElement.scrollTop > 100);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", onScroll);
  }, []);

  const {
    pageTitle,
    navbarLinks,
    navbarButton,
    heroTitle,
    heroDescription,
    heroImage,
    heroInputPlaceholder,
    heroButtonText,
    aboutUsTitle,
    aboutUsTitle2,
    aboutUsDescription,
    aboutUsImage,
    ourServiceTitle,
    ourServiceTitle2,
    services,
    noCodeTitle,
    noCodeTitle2,
    noCodeSlider,
    clientTitle,
    clientTitle2,
    clientFeatures,
    talentTitle,
    talentTitle2,
    talentFeatures,
    newsletterTitle,
    newsletterDescription,
    newsletterInputPlaceholder,
    newsletterButtonText,
    footerLink,
    footerCopyright,
    footerLinkedin,
    footerFacebook,
    footerInstagram,
  } = data.home;

  return (
    <ChakraProvider theme={theme}>
      <Helmet>
        <title>Lowlancer - Be Adaptive Need an app, but.like.FAST</title>
        <meta
          name="description"
          content="If you need some work done but quickly you can do it using no code platforms. Then come to lowlancer."
        />
      </Helmet>
      <Navbar
        active={isVisible}
        data={{ pageTitle, navbarLinks, navbarButton }}
      />
      <BlurryBackground>
        <Hero
          data={{
            heroTitle,
            heroDescription,
            heroImage,
            heroInputPlaceholder,
            heroButtonText,
          }}
        />
        <About
          data={{
            aboutUsTitle,
            aboutUsTitle2,
            aboutUsDescription,
            aboutUsImage,
          }}
        />
      </BlurryBackground>
      <Service data={{ ourServiceTitle, ourServiceTitle2, services }} />
      <BlurryBackground onlyLarge>
        <Tools data={{ noCodeTitle, noCodeTitle2, noCodeSlider }} />
      </BlurryBackground>
      <BlurryBackground isContain>
        <Client data={{ clientTitle, clientTitle2, clientFeatures }} />
        <Talent data={{ talentTitle, talentTitle2, talentFeatures }} />
        <Newsletter
          data={{
            newsletterTitle,
            newsletterDescription,
            newsletterInputPlaceholder,
            newsletterButtonText,
          }}
        />
      </BlurryBackground>
      <Footer
        data={{
          footerLink,
          footerCopyright,
          footerLinkedin,
          footerFacebook,
          footerInstagram,
        }}
      />
    </ChakraProvider>
  );
};

export const query = graphql`
  query HomePageQuery {
    home: contentfulLowlancerHomePage {
      pageTitle
      navbarLinks {
        text
        link
      }
      navbarButton {
        text
        link
      }
      heroTitle
      heroDescription {
        heroDescription
      }
      heroImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      heroInputPlaceholder
      heroButtonText
      aboutUsTitle
      aboutUsTitle2
      aboutUsDescription {
        aboutUsDescription
      }
      aboutUsImage {
        gatsbyImageData(placeholder: BLURRED)
      }
      ourServiceTitle
      ourServiceTitle2
      services {
        title
        image {
          file {
            url
          }
        }
      }
      noCodeTitle
      noCodeTitle2
      noCodeSlider {
        id
        image {
          file {
            url
          }
        }
        title
      }
      clientTitle
      clientTitle2
      clientFeatures {
        title
        image {
          file {
            url
          }
        }
      }
      talentTitle
      talentTitle2
      talentFeatures {
        title
        image {
          file {
            url
          }
        }
      }
      newsletterTitle
      newsletterDescription
      newsletterInputPlaceholder
      newsletterButtonText
      footerLink {
        text
        link
      }
      footerCopyright
      footerLinkedin
      footerFacebook
      footerInstagram
    }
  }
`;

export default IndexPage;
