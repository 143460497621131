import * as React from "react";
import {
  Box,
  Flex,
  Container,
  Heading,
  SimpleGrid,
  Text,
} from "@chakra-ui/react";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

const About = ({ data }) => {
  const image = getImage(data.aboutUsImage);

  return (
    <div id="about">
      <Box my={16} p={{ base: 4 }}>
        <Container maxW="container.lg" px={{ base: 0, lg: 4 }}>
          <SimpleGrid columns={{ base: 1, lg: 2 }}>
            <Box pr={{ base: 0, lg: 8 }} py={8} order={{ base: 2, lg: 1 }}>
              <GatsbyImage image={image} />
            </Box>
            <Flex
              align="center"
              justify={{ base: "center", lg: "start" }}
              order={{ base: 1, lg: 2 }}
            >
              <Box>
                <Text color="brandPrimary">{data.aboutUsTitle}</Text>
                <Heading as="h1" py={4} fontSize={{ base: "24px", lg: "3xl" }}>
                  {data.aboutUsTitle2}
                </Heading>
                <Text color="brandBodyText">
                  {data.aboutUsDescription.aboutUsDescription}
                </Text>
              </Box>
            </Flex>
          </SimpleGrid>
        </Container>
      </Box>
    </div>
  );
};

export default About;
